import {mapGetters} from "vuex";

export default {
    computed:{
        ...mapGetters({
            ttnNotification:'orders/ttnNotification'
        }),
    },
    created() {
        if(this.ttnNotification.models.length){
            const audio = new Audio(require('../../../../assets/sounds/MESSAGE.mp3'))
            audio.play();
        }
    },
    methods:{
        closePopup(){
            this.$emit('closePopup');
        }
    }
}